@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root{
  --primary:#1aac83;
  --error:#e7195a;
}
html{
  overflow: hidden;
  overflow-y: scroll;
}
body{
  margin:0;
  font-family: "Poppins";
  background-color: #f1f1f1;

}
header{
  background-color: #fff;
}
header .container{
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  padding:0 20px;
}

header a {
  text-decoration: 0;
  color:#333;
}

nav{
  display: flex;
  align-items: center;
}
nav a{
  margin-left: 10px;
}

nav button{
  background-color: #fff;
  color:var(--primary);
  padding:6px 10px;
  border: 2px solid var(--primary);
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 1px;
  font-family: "Poppins";
  margin-left: 10px;
}
/* pages */
.pages{
  max-width: 1000px;
  margin:0 auto;
  padding:20px;
}

/* Home */
.home{
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}
.todos{
  position: relative;
}
.empty-todos{
  width: 100%;
  text-align: center;
  font-size: 1em;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.todo-list{
  background-color: #fff;
  /* padding:20px; */
  margin:20px 0;
  border-radius: 4px;
  box-shadow: 3px 2px 5px rgba(0, 0, 0,0.5);
  position: relative;
}
.todo-content {
  background: linear-gradient(var(--primary) 0 0) no-repeat calc(200% - var(--p, 0%))
  100% / 200% var(--p, 0.08em);
  transition: 0.3s var(--t, 0s),
  background-position 0.3s calc(0.3s - var(--t, 0s));
  padding:20px;
}
.todo-content:hover {
  --p: 100%;
  --t: 0.3s;
}
.todo-list .todo-content:hover h4,.todo-list:hover .todo-content p{
  color: #fff !important;
  transition-delay:0.3s;
  /* color: #fff; */

}
.todo-list a{
  text-decoration: none;
}
.todo-list .todo-content h4{
  font-size: 1.2em;
  margin:0 0 10px 0;
  color: var(--primary);
}
.todo-list .todo-content p{
  font-size:0.9em;
  color: #555;
  margin:0;
}

.todo-list span{
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--error);
  color: #fff;
  padding: 4px;
  font-size: 1.1em;
}
/* form */
form h4{
  font-size: 1em;
}
label,input,textarea{
  display: block;
  box-sizing: border-box;
}
input,textarea{
  width: 100%;
  padding:10px;
  margin:10px 0 20px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-family: "Poppins";
  outline: none;
}

form button{
  color: #fff;
  background-color:var(--primary);
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  margin-bottom: 8px;
  padding:8px;
  font-family: "Poppins";
}
.error{
  border:1px solid var(--error) ;
}
div.error{
  background-color: #ffefef;
  color: var(--error);
  padding:10px;
  margin:20px 0;
  border-radius: 4px;
}

form.login h3,form.signup h3{
  text-align: center;
  margin:10px 0;
  font-size: 1.5em;
}

form.login,form.signup{
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  margin:40px auto;
  max-width: 400px;
  padding:20px;
}

/* TODO DETAILS */
.todo-details article h2{
  color: var(--primary);
  font-size: 1.5em;
  margin: 0 0 20px;
}
.todo-details article p{
  margin: 0 0 20px 4px;
  font-size: 1em;
  font-weight: lighter;
}
.todo-details article{
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 10px;
  margin: 40px 0;
  position: relative;
  white-space:pre-wrap;
}
.todo-details span{
  color:#706c6c;
  cursor: pointer;
  font-size: 1.3em;
  position: absolute;
  top: 20px;
  right: 20px;
}
.todo-details .popup-form{
  box-shadow: 5px 5px 100px rgba(0, 0, 0,0.5);
  padding: 20px;
  border-radius:4px;
  background-color: #fff;
  position: absolute;
  min-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.todo-details .popup-form h4{
  font-size: 1.5em;
  margin:10px 0;
}
.todo-details .popup-form span{
  font-size: 1.6em;
}
